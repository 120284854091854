<template>
  <CModal :show="true" :closeOnBackdrop="false" :centered="true">
    <template #header>
      <h5 class="modal-title">{{$t('family.dashboard.zip_modal.title')}}</h5>
    </template>

    <p>
      {{ $t('family.dashboard.zip_modal.text') }}
    </p>

    <CAlert v-if="error.hasGeneralErrors()" color="danger" class="mb-3">
      {{ error.general().join(" ") }}
    </CAlert>

    <form action="" @submit.prevent="onSubmit">
      <CInput
        v-model="form.to"
        :label="$t('models.family_address.to')"
        type="text"
        :isValid="error.isValidField('to')"
        :invalidFeedback="error.fieldError('to')"
        required
      />

      <CInput
        v-model="form.address"
        :label="$t('models.family_address.address')"
        type="text"
        :isValid="error.isValidField('address')"
        :invalidFeedback="error.fieldError('address')"
        required
      />

      <CInput
        v-model="form.city"
        :label="$t('models.family_address.city')"
        type="text"
        :isValid="error.isValidField('city')"
        :invalidFeedback="error.fieldError('city')"
        required
      />

      <CSelect
        :label="$t('models.family_address.country')"
        :options="countryOptions"
        :value.sync="form.country"
        :isValid="error.isValidField('country')"
        :invalidFeedback="error.fieldError('country')"
        custom
      />

      <div class="form-group">
        <label>{{ $t("models.family_address.postal_code") }}</label>
        <v-select
          class="bg-white"
          label="code"
          v-model="form.postal_code"
          :options="postalCodeOptions"
          @search="fetchPostalCodeOptions"
        />
        <div
          v-if="error.hasFieldsError('postal_code')"
          class="invalid-feedback d-block"
        >
          {{ error.fieldError("postal_code") }}
        </div>
      </div>
    </form>

    <template #footer>
      <vue-ladda
        :loading="loading"
        data-style="zoom-in"
        button-class="btn btn-primary px-4"
        @click.prevent="onSubmit"
        >{{ $t('common.editor.confirm') }}</vue-ladda
      >
    </template>
  </CModal>
</template>

<script>
import get from "lodash/get";
import debounce from "lodash/debounce";
import pick from "lodash/pick";
import errorResponse from "../../helpers/error";
import {
  getNationsActiveOptions,
  getPostalCodeOptions,
} from "../../helpers/options";
import vSelect from "vue-select";
import { GetDataManagerNew } from "../../ds";
import EventBus from "../../helpers/EventBus";

const formDefaults = () => {
  return {
    id: null,
    to: "",
    address: "",
    city: "",
    postal_code: null,
    country: "",
    default_ship: true,
    default_bill: true,
  };
};

export default {
  name: "AddressesModal",
  components: {
    vSelect,
  },

  data() {
    const familyId = this.$store.state.connections.current.family.id;
    const dm = GetDataManagerNew("auth_faddress", [familyId]);

    return {
      dm: dm,
      initialized: false,
      loading: false,
      error: errorResponse(),
      form: formDefaults(),
      countryOptions: [],
      postalCodeOptions: [],
    };
  },

  mounted(){
    this.init();
  },

  watch: {
    // show(value) {
    //   if (value === true) {
    //     this.init();
    //   }
    // },
    "form.country": function () {
      if (this.initialized) {
        this.form.postal_code = null;
      }
    },
  },

  methods: {
    init() {
      this.initialized = false;
      // this.form = this.isEdit ? { ...this.values } : formDefaults();
      this.getCountryOptions();
      this.$nextTick(() => {
        this.initialized = true;
      });
    },

    getCountryOptions() {
      getNationsActiveOptions().then((options) => {
        this.countryOptions = [
          { value: null, label: "- "+this.$t('family.settings.addresses.modal.country_select')+" -" },
          ...options,
        ];
      });
    },

    getPostalCodeOptions: debounce((search, loading, countryId, vm) => {
      getPostalCodeOptions({ countryId, search }).then((options) => {
        loading(false);
        vm.postalCodeOptions = [...options];
      });
    }, 350),

    fetchPostalCodeOptions(search, loading) {
      const countryId = this.form.country;
      if (countryId && search.length) {
        loading(true);
        this.getPostalCodeOptions(search, loading, countryId, this);
      }
    },

    onSubmit() {
      this.addAddress();
    },

    prepareRequestParams(formData) {
      const fields = [
        "id",
        "to",
        "address",
        "city",
        "country",
        "default_ship",
        "default_bill",
      ];

      return {
        ...pick(formData, fields),
        postal_code: get(formData.postal_code, "id", ""),
      };
    },

    addAddress() {
      const requestParams = {
        ...this.prepareRequestParams(this.form),
      };
      this.error.reset();
      this.dm
        .insert(requestParams)
        .then((response) => {
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: this.$t('family.settings.addresses.actions.msg_add_ok'),
            color: "success",
            autohide: true,
          });
          this.$store.state.connections.current.zip_code=response.postal_code.code
        })
        .catch((response) => {
          const body = JSON.parse(response[0].error.response);
          this.error.set(body.errors);
        });
    },
  },
};
</script>
